<template>
  <discounts-form
    ref="discounts-form"
    :is-create-form="true"
    :is-loading="false"
  />
</template>
<script>
import DiscountsForm from '@/views/settings/catalog/pricing/components/DiscountsForm.vue'

export default {
  name: 'DiscountsCreate',
  inject: ['discountsModuleName'],
  components: {
    DiscountsForm,
  },

  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME = 'discounts'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
